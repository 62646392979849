import { NextSeo } from 'next-seo';
import { OpenGraph, Twitter } from 'next-seo/lib/types';

import { SITE_NAME, SITE_URL, SOCIAL_TWITTER_ID } from '@/lib/constants';

interface BaseSeoProps {
  title?: string;
  path?: string;
  description?: string;
  ogDescription?: string;
  ogTitle?: string;
  twitter?: Twitter;
  image?: string | null;
}

const trimTrailingSlash = (slug: string) => {
  return slug[slug.length - 1] === '/' ? slug.slice(0, slug.length - 1) : slug;
};

export const BaseSeo = ({
  title,
  ogTitle,
  description,
  ogDescription,
  path,
  image
}: BaseSeoProps) => {
  const canonical = path ? `${SITE_URL}${trimTrailingSlash(path)}` : SITE_URL;
  const baseTitle = SITE_NAME;
  const baseDescription =
    'Colophon is an international, award-winning type foundry based in London (UK) and Los Angeles (US). We create, publish, and distribute high-quality retail and custom typefaces for analog and digital media.';

  const seoTitle = title || baseTitle;
  const seoDescription = description || baseDescription;

  const twitter = SOCIAL_TWITTER_ID
    ? {
        handle: SOCIAL_TWITTER_ID,
        cardType: 'summary_large_image'
      }
    : null;

  const openGraph: OpenGraph = {
    url: canonical,
    title: ogTitle || seoTitle,
    description: ogDescription || seoDescription,
    images: [
      {
        url: `${SITE_URL}${image ? image : '/img/og.png'}`,
        alt: 'Colophon Foundry'
      }
    ],
    site_name: 'Colophon Foundry'
  };

  return (
    <NextSeo
      title={seoTitle}
      description={seoDescription}
      canonical={canonical}
      twitter={twitter}
      openGraph={openGraph}
    />
  );
};

export default BaseSeo;
