import StoryblokClient from 'storyblok-js-client';

const STORYBOOK_API_KEY = process.env.NEXT_PUBLIC_STORYBLOK_PREVIEW_TOKEN;

export const Storyblok = new StoryblokClient({
  accessToken: STORYBOOK_API_KEY,
  cache: {
    clear: 'auto',
    type: 'memory'
  }
});
